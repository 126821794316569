import { useState, useEffect } from 'react';

export default function ConfirmPartsModal({ venta, piezas, onConfirmPartsModal }) {
  /** States **/
  const [listaPiezas, setListaPiezas] = useState([]);

  /** Lifecycle **/
  useEffect(() => {
    const uniquePiezas = new Map();
  
    piezas.forEach((pieza) => {
      uniquePiezas.set(pieza.id, {
        piezaId: pieza.id,
        ventaId: venta.id,
        confirmacion: pieza.confirmacion,
        proveedorId: pieza.proveedor?.id,
      });
    });
  
    setListaPiezas(Array.from(uniquePiezas.values()));
  }, [piezas, venta.id]);

  /** Methods **/
  const handleConfirmPartsModal = () => {
    onConfirmPartsModal(listaPiezas);
  };
  const onChangePartConfirmation = (state, proveedorId) => {
    const updatedPiezas = listaPiezas.map((pieza) => {
      if (pieza.proveedorId === proveedorId) {
        return {
          ...pieza,
          confirmacion: state,
        };
      }
      return pieza;
    });
    setListaPiezas(updatedPiezas);
  }

  /** Render **/
  return (
    <div className="d-flex flex-column align-items-end">
      <div className="mb-4 d-flex flex-column gap-3 w-100">
        {piezas?.map((pieza) => (
          <div key={pieza.id} className="d-flex align-items-center">
            <input
              type="checkbox"
              className="ms-0"
              defaultChecked={pieza.confirmacion}
              id={`checkbox-${pieza.id}`}
              style={{ cursor: "pointer" }}
              onClick={(e) => onChangePartConfirmation(e.target.checked, pieza.proveedor?.id)}
            />
            <label htmlFor={`checkbox-${pieza.id}`} className="ms-2 fs-6 d-flex align-items-center" style={{ cursor: "pointer" }}>
              <span>[ID: <strong>{pieza.id}</strong>]</span>
              <span
                className="text-truncate"
                style={{
                  display: "inline-block",
                  maxWidth: "400px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  verticalAlign: "middle",
                  margin: "0 0 0 .5rem",
                }}
                title={pieza.nombre}
              >
                {pieza.nombre}
              </span>
            </label>
          </div>
        ))}
      </div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleConfirmPartsModal}
      >
        Confirmar
      </button>
    </div>
  );
}